
import TheEmployeesDatatable from '@/views/mc-admin-employees/TheEmployeesDatatable.vue'
import { UploadFilled } from '@element-plus/icons-vue'
import FilterDropdown from '@/views/mc-admin-session/FilterDropdown.vue'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { defineComponent, ref, onMounted, computed, inject, watch } from 'vue'
import { useStore } from 'vuex'
import { setCurrentPageTitle, setCurrentPageTitleButton } from '@/core/helpers/breadcrumb'
import { getMedia } from '@/core/mc-admin-helpers/assets'
import {
  getStatusBadge,
  formatListDate,
  formatListHourInterval
} from '@/core/mc-admin-helpers/activity-session'
import {
  buildTemplateImageUrl,
  formatActivitiesTableDate,
  formatSecondsToMinutes,
  getEtagForTemplate,
  formatHumanDate,
  buildApiResourceUrl
} from '@/core/mc-admin-helpers/activity-template'

import {
  buildDownloadSampleUrl
} from '@/core/mc-admin-helpers/employee'
import { Actions, MutationsActions } from '@/store/enums/StoreEnums'
import { ActivityTemplateFilter } from '@/store/modules/TemplateModule'
import TheDeleteActivityModal from '@/views/mc-admin-library-manager/TheDeleteActivityModal.vue'
import { MenuComponent } from '@/assets/ts/components'
import { useCookies } from 'vue3-cookies'
import { UploadFile } from 'element-plus/lib/components/upload/src/upload.type'
import TheManuallyAddEmployeeForm from '@/views/mc-admin-employees/TheManuallyAddEmployeeForm.vue'
import ApiService from '@/core/services/ApiService'
import TheEditEmployeeForm from '@/views/mc-admin-employees/TheEditEmployeeForm.vue'
export default defineComponent({
  name: 'employees',
  components: {
    TheManuallyAddEmployeeForm,
    TheEmployeesDatatable,
    FilterDropdown,
    TheDeleteActivityModal,
    TheEditEmployeeForm,
    UploadFilled
  },
  setup () {
    const emitter = inject('emitter') as any // Inject `emitter`
    const { cookies } = useCookies()
    emitter.on('add-employees', (value) => {
      modalAddEmployeesVisible.value = true
    })
    const downloadUrl = buildDownloadSampleUrl()
    const modalAddEmployeesVisible = ref(false)
    const modalEditEmployeeVisible = ref(false)
    const uploadEmployeesRef = ref<any>()
    const uploadErrorMessage = ref<string>('')
    const uploadSuccess = ref<object>({}) as any

    const buildAuthorizationToken = computed(() => ApiService.getAuthorizationHeader()) as any
    const onSubmitUpload = () => {
        uploadEmployeesRef.value!.submit()
    }

    const onUploadUsersError = (error: Error, uploadFile: UploadFile) => {
      const errorObject = JSON.parse(error.message)
      uploadErrorMessage.value = errorObject.error.message
    }

    const onCreateUserSuccess = () => {
      let payload = {} as any
      payload = {
        filter: {
          limit: itemsPerPage.value,
          offset: itemsPerPage.value * (selectedPage.value - 1)
        }
      }
      if (tableSearch.value !== '') {
        payload.filter.keyword = tableSearch.value
      }

      const countPayload = { filter: { } } as any
      if (tableSearch.value !== '') {
        countPayload.filter.keyword = tableSearch.value
      }
      store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES, payload).then(() => {
        store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES_COUNT, countPayload).then(() => {
          MenuComponent.reinitialization()
          modalAddEmployeesVisible.value = false
          modalEditEmployeeVisible.value = false
        })
      })
    }

    const onUploadUsersSuccess = (response: any, uploadFile: UploadFile) => {
      uploadSuccess.value.inserted = response.payload.inserted
      uploadSuccess.value.parsed = response.payload.parsed
      uploadSuccess.value.skipped = response.payload.skipped
      let payload = {} as any
      payload = {
        filter: {
          limit: itemsPerPage.value,
          offset: itemsPerPage.value * (selectedPage.value - 1)
        }
      }
      if (tableSearch.value !== '') {
        payload.filter.keyword = tableSearch.value
      }

      const countPayload = { filter: { } } as any
      if (tableSearch.value !== '') {
        countPayload.filter.keyword = tableSearch.value
      }
      store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES, payload).then(() => {
        store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES_COUNT, countPayload).then(() => {
          MenuComponent.reinitialization()
        })
      })
    }

    const onUploadUsersChange = (event) => {
      // clear error message
      if (event.status !== 'fail') {
        uploadErrorMessage.value = ''
      }
    }

    const onUploadUsersRemove = (uploadFile: UploadFile) => {
      uploadSuccess.value.inserted = 0
      uploadSuccess.value.parsed = 0
      uploadSuccess.value.skipped = 0
    }

    const onActivityCardSwitched = (value) => {
      console.log('switched: ', value)
    }
    const templateToDelete = ref({})
    const onShowDelete = (template) => {
      console.log(template)
      templateToDelete.value = template
    }

    const tableHeader = ref([
      {
        name: 'Employee',
        key: 'employee',
        sortable: false
      },
      {
        name: 'Business Unit',
        key: 'businessUnit',
        sortable: false
      },
      {
        name: 'Division',
        key: 'division',
        sortable: false
      },
      {
        name: 'Department',
        key: 'department',
        sortable: false
      },
      {
        name: 'Cost Centre',
        key: 'costCentre',
        sortable: false
      },
      {
        name: 'Manager',
        key: 'manager',
        sortable: false
      },
      {
        name: '',
        key: 'actions',
        sortable: false
      }

    ])
    const activitySwitched = ref<boolean>(false)
    const isActivitySwitched = (status) => ref(computed(() => {
      return status === 2
    }))

    const onSorted = (sortBy) => {
      activeSortBy.value = sortBy
      let payload = {} as ActivityTemplateFilter
      payload = {
        filter: {
          limit: itemsPerPage.value,
          offset: itemsPerPage.value * (selectedPage.value - 1),
          status: activeFilterBy.value
        }
      }
      payload.filter.orderBy = {}
      payload.filter.orderBy[sortBy] = 'desc'
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES, payload)
    }

    const onFilterSelected = (filterType) => {
      activeFilterBy.value = filterType
      let payload = {} as ActivityTemplateFilter
      payload = {
        filter: {
          limit: itemsPerPage.value,
          offset: itemsPerPage.value * (selectedPage.value - 1),
          status: filterType
        }
      }
      payload.filter.orderBy = {}
      payload.filter.orderBy[activeSortBy.value] = 'desc'
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES, payload)
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES_COUNT, payload)
    }

    const activeSortBy = ref<string>('lastOpened')
    const activeFilterBy = ref<number>(0)

    const tableSearch = ref<string>('')
    const itemsPerPage = ref<number>(5)
    const selectedPage = ref<number>(1)

    const store = useStore()
    const employeeToEdit = ref({}) as any
    const tableData = ref(computed(() => store.getters.getEmployees))
    const employeesCount = computed(() => store.getters.getEmployeesCount)
    const tablePagesCount = computed(() => Math.ceil(employeesCount.value / itemsPerPage.value))
    const tableLoading = computed(() => store.getters.getEmployeesLoading)

    let timeoutId = 0 as any

    const onItemsSearch = () => {
      clearTimeout(timeoutId)
      let payload = {} as any
      payload = {
        filter: {
          keyword: tableSearch.value,
          limit: itemsPerPage.value,
          offset: itemsPerPage.value * (selectedPage.value - 1)
        }
      }
      const countPayload = { filter: { keyword: tableSearch.value } }
      timeoutId = setTimeout(() => {
        store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES, payload).then(() => {
          store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES_COUNT, countPayload).then(() => {
            MenuComponent.reinitialization()
          })
        })
      }, 500)
    }

    const onCurrentPageChange = (page) => {
      selectedPage.value = page
      let payload = {} as any
      payload = {
        filter: {
          limit: itemsPerPage.value,
          offset: itemsPerPage.value * (page - 1)
        }
      }
      if (tableSearch.value !== '') {
        payload.filter.keyword = tableSearch.value
      }
      store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES, payload)
    }

    const onItemsPerPageChange = (rowsPerPage, page) => {
      itemsPerPage.value = rowsPerPage
      let payload = {} as any
      payload = {
        filter: {
          limit: rowsPerPage,
          offset: rowsPerPage * (selectedPage.value - 1)
        }
      }
      if (tableSearch.value !== '') {
        payload.filter.keyword = tableSearch.value
      }
      store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES, payload)
    }
    const onEditEmployeeAction = (employee) => {
      // employeeToEdit.value = {} as any
      store.dispatch(Actions.API_GET_EMPLOYEE, employee.userUuid).then((response) => {
        employeeToEdit.value = response.payload
        modalEditEmployeeVisible.value = true
      }).catch(() => {
        modalEditEmployeeVisible.value = false
      })
    }
    const onDeleteAction = (employee) => {
      store.dispatch(Actions.API_DELETE_EMPLOYEE, employee.userUuid).then((response) => {
        let payload = {} as any
        payload = {
          filter: {
            limit: itemsPerPage.value,
            offset: itemsPerPage.value * (selectedPage.value - 1)
          }
        }
        if (tableSearch.value !== '') {
          payload.filter.keyword = tableSearch.value
        }

        const countPayload = { filter: { } } as any
        if (tableSearch.value !== '') {
          countPayload.filter.keyword = tableSearch.value
        }
        store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES, payload).then(() => {
          store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES_COUNT, countPayload).then(() => {
            MenuComponent.reinitialization()
          })
        })
      }).catch((response) => {
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      })
    }

    onMounted(() => {
      watch(() => tableData.value, (updated, oldAccount) => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading')
      })
      const employeesPayload = { filter: { limit: 5, offset: 0 } } as any
      store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES, employeesPayload).then(() => {
        store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES_COUNT).then(() => {
          // dropdown menus need to be reinitialized
          MenuComponent.reinitialization()
        })
      })

      setCurrentPageTitle('Employee manager')
      setCurrentPageTitleButton({ name: 'Add employees', url: '', class: 'houston-btn-dark houston-btn-bounce', emits: { actionName: 'add-employees' } })
    })

    return {
      tableHeader,
      tableData,
      tableSearch,
      tablePagesCount,
      onItemsSearch,
      onItemsPerPageChange,
      onCurrentPageChange,
      onDeleteAction,
      getMedia,
      getStatusBadge,
      formatListDate,
      formatActivitiesTableDate,
      formatSecondsToMinutes,
      formatListHourInterval,
      formatHumanDate,
      onActivityCardSwitched,
      activitySwitched,
      isActivitySwitched,
      onSorted,
      onShowDelete,
      onFilterSelected,
      activeFilterBy,
      buildTemplateImageUrl,
      buildDownloadSampleUrl,
      getEtagForTemplate,
      templateToDelete,

      modalAddEmployeesVisible,
      uploadEmployeesRef,
      tableLoading,
      onSubmitUpload,
      buildApiResourceUrl,
      onUploadUsersError,
      onUploadUsersChange,
      onUploadUsersSuccess,
      onUploadUsersRemove,
      uploadSuccess,
      uploadErrorMessage,
      cookies,
      downloadUrl,
      employeesCount,
      onCreateUserSuccess,
      buildAuthorizationToken,
      modalEditEmployeeVisible,
      onEditEmployeeAction,
      employeeToEdit
    }
  }
})
